import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("lab")}>Lab Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for Labs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Test Selection & Customization</h3>
                                    <ul>
                                        <li><span>User-friendly interface for selecting specific lab tests.</span></li>
                                        <li><span>Customization options for tailored testing packages.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling</h3>
                                    <ul>
                                        <li><span>Online calendar for scheduling lab appointments.</span></li>
                                        <li><span>Notifications and reminders for upcoming appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lab Locator & Directions</h3>
                                    <ul>
                                        <li><span>Integration with maps for locating nearby labs.</span></li>
                                        <li><span>Directions and information on lab hours and services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Test Results Access</h3>
                                    <ul>
                                        <li><span>Secure online access to lab test results.</span></li>
                                        <li><span>Notifications for result availability and status updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Electronic Prescriptions for Tests</h3>
                                    <ul>
                                        <li><span>Integration with healthcare providers for electronic test prescriptions.</span></li>
                                        <li><span>Secure transmission of test orders to the chosen labs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transparent Pricing Information</h3>
                                    <ul>
                                        <li><span>Display of transparent pricing for each lab test.</span></li>
                                        <li><span>Information on insurance coverage and out-of-pocket costs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Record Integration</h3>
                                    <ul>
                                        <li><span>Integration with personal health records for comprehensive health data.</span></li>
                                        <li><span>Access to past lab results for trend analysis.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources</h3>
                                    <ul>
                                        <li><span>Access to educational materials on different lab tests.</span></li>
                                        <li><span>Informational articles and videos for user awareness.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Messaging with Lab Professionals</h3>
                                    <ul>
                                        <li><span>In-app messaging for secure communication with lab professionals.</span></li>
                                        <li><span>Clarification of test-related queries and information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span>User-friendly mobile applications for convenient lab management.</span></li>
                                        <li><span>Compatibility with various devices for flexibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Online Payment & Billing</h3>
                                    <ul>
                                        <li><span>Integration with secure payment gateways for test payments.</span></li>
                                        <li><span>Electronic billing and invoicing for transparent transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment History & Records</h3>
                                    <ul>
                                        <li><span>Overview of past lab appointments and test history.</span></li>
                                        <li><span>Detailed records of performed tests and associated information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Rating System</h3>
                                    <ul>
                                        <li><span>User feedback system for rating lab experiences.</span></li>
                                        <li><span>Mechanisms for continuous improvement based on user suggestions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Monitoring Integration</h3>
                                    <ul>
                                        <li><span>Integration with health monitoring devices for real-time data.</span></li>
                                        <li><span>Monitoring of specific health metrics relevant to lab tests.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Rescheduling & Cancellation</h3>
                                    <ul>
                                        <li><span>Tools for rescheduling or cancelling lab appointments.</span></li>
                                        <li><span>Clear guidelines on cancellation policies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Notification Alerts for Abnormal Results</h3>
                                    <ul>
                                        <li><span>Real-time alerts for abnormal or critical test results.</span></li>
                                        <li><span>Guidance on next steps and recommended actions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Upload for Follow-Up Tests</h3>
                                    <ul>
                                        <li><span>Secure options for uploading and verifying follow-up test prescriptions.</span></li>
                                        <li><span>Integration with healthcare providers for prescription validation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Family Account Management</h3>
                                    <ul>
                                        <li><span>Features for managing lab profiles for family members.</span></li>
                                        <li><span>Coordinated scheduling for family lab appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Environmental Impact Information</h3>
                                    <ul>
                                        <li><span>Information on the environmental impact of lab testing processes.</span></li>
                                        <li><span>Integration with sustainability initiatives and reporting.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment & Service Reviews</h3>
                                    <ul>
                                        <li><span>User reviews and ratings for lab services.</span></li>
                                        <li><span>Option to provide feedback on the overall lab experience.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="lab">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Lab Side Features for Labs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management System</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for managing user accounts and appointments.</span></li>
                                        <li><span>Tools for efficient user registration and profile management.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling & Management</h3>
                                    <ul>
                                        <li><span>Online calendar for scheduling and managing lab appointments.</span></li>
                                        <li><span>Automated appointment reminders for users.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Test Results Transmission</h3>
                                    <ul>
                                        <li><span>Secure platform for transmitting lab test results to users.</span></li>
                                        <li><span>Encryption measures to protect sensitive health data.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Electronic Test Orders & Prescriptions</h3>
                                    <ul>
                                        <li><span>Integration with healthcare providers for electronic test prescriptions.</span></li>
                                        <li><span>Secure transmission of test orders with complete details.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Health Records</h3>
                                    <ul>
                                        <li><span>Integration with users' personal health records.</span></li>
                                        <li><span>Access to relevant health information for better test interpretation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transparent Pricing Information</h3>
                                    <ul>
                                        <li><span>Display of transparent pricing for each lab test.</span></li>
                                        <li><span>Information on insurance coverage and payment options.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Messaging with Users</h3>
                                    <ul>
                                        <li><span>In-app messaging for secure communication with users.</span></li>
                                        <li><span>Clarification of test-related queries and coordination for optimal service.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Test Customization Options</h3>
                                    <ul>
                                        <li><span>System for customizing testing packages based on user needs.</span></li>
                                        <li><span>Options for tailoring tests to specific health conditions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility for Lab Professionals</h3>
                                    <ul>
                                        <li><span>User-friendly mobile applications for lab professionals.</span></li>
                                        <li><span>Compatibility with various devices for flexibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Online Payment & Billing System</h3>
                                    <ul>
                                        <li><span>Integration with secure payment gateways for test payments.</span></li>
                                        <li><span>Electronic billing and invoicing for streamlined financial processes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Quality Control & Assurance</h3>
                                    <ul>
                                        <li><span>Tools for ensuring the accuracy and reliability of lab tests.</span></li>
                                        <li><span>Quality control measures for maintaining high testing standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment History & Records Oversight</h3>
                                    <ul>
                                        <li><span>Overview of past lab appointments and test history.</span></li>
                                        <li><span>Detailed records of performed tests and associated information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Rating System Monitoring</h3>
                                    <ul>
                                        <li><span>Monitoring system for user feedback and ratings.</span></li>
                                        <li><span>Analysis of feedback for continuous improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Monitoring Device Integration</h3>
                                    <ul>
                                        <li><span>Integration with health monitoring devices for real-time data.</span></li>
                                        <li><span>Utilization of specific health metrics relevant to lab tests.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Environmental Impact Information Display</h3>
                                    <ul>
                                        <li><span>Display of information on the environmental impact of lab testing processes.</span></li>
                                        <li><span>Integration with sustainability initiatives and reporting.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Rescheduling & Cancellation Tools</h3>
                                    <ul>
                                        <li><span>Tools for efficiently handling appointment rescheduling or cancellations.</span></li>
                                        <li><span>Clear guidelines on managing changes in user appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Validation & Verification System</h3>
                                    <ul>
                                        <li><span>System for validating and verifying prescriptions for follow-up tests.</span></li>
                                        <li><span>Integration with healthcare providers for prescription authentication.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Family Account Management Tools</h3>
                                    <ul>
                                        <li><span>Features for managing lab profiles for family members.</span></li>
                                        <li><span>Coordination and scheduling options for family lab appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Response Coordination</h3>
                                    <ul>
                                        <li><span>Integration with emergency services for urgent test situations.</span></li>
                                        <li><span>Coordination with emergency response teams as needed.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Labs in E-Health Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lab Management Dashboard</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for overseeing and managing labs.</span></li>
                                        <li><span>Tools for efficient onboarding, verification, and monitoring of labs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User & Appointment Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for overseeing user accounts and lab appointments.</span></li>
                                        <li><span>Support for resolving user-related issues and optimizing appointment scheduling.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Compliance Monitoring</h3>
                                    <ul>
                                        <li><span>Implementation of robust security measures to protect patient data.</span></li>
                                        <li><span>Regular compliance checks with healthcare data protection regulations and standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Customization & Settings</h3>
                                    <ul>
                                        <li><span>Controls for customizing platform settings and configurations.</span></li>
                                        <li><span>Adaptability to changing market conditions and regulatory requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics Dashboard</h3>
                                    <ul>
                                        <li><span>Comprehensive analytics dashboard for tracking lab performance.</span></li>
                                        <li><span>Reports on appointment trends, user demographics, and user satisfaction.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication & Announcement Tools</h3>
                                    <ul>
                                        <li><span>Tools for communicating with labs.</span></li>
                                        <li><span>Announcement features for platform-wide updates, policy changes, and news.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lab Verification & Compliance</h3>
                                    <ul>
                                        <li><span>Verification processes for onboarding new labs.</span></li>
                                        <li><span>Regular checks to ensure labs adhere to platform guidelines.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Test Results Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing the transmission of lab test results to users.</span></li>
                                        <li><span>Support for resolving issues related to result delivery and interpretation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Electronic Test Orders Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for overseeing electronic test orders and prescriptions.</span></li>
                                        <li><span>Assistance in resolving issues related to test orders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Insurance Verification Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing insurance verification processes.</span></li>
                                        <li><span>Assistance in addressing issues related to insurance claims and coverage.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Services Integration Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of integration with emergency services for urgent lab situations.</span></li>
                                        <li><span>Coordination with emergency response teams in critical situations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User & Lab Feedback System</h3>
                                    <ul>
                                        <li><span>System for collecting feedback and ratings from users and labs.</span></li>
                                        <li><span>Analysis of feedback for continuous platform and service improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Environmental Impact Monitoring</h3>
                                    <ul>
                                        <li><span>Tools for tracking and reporting on the environmental impact of lab testing processes.</span></li>
                                        <li><span>Integration with sustainability initiatives and reporting.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Continuing Professional Development (CPD) Tracking Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of tools for tracking lab professionals' CPD credits.</span></li>
                                        <li><span>Integration with CPD platforms for seamless recordkeeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Updates & Maintenance</h3>
                                    <ul>
                                        <li><span>Tools for managing updates to the E-Health platform and applications.</span></li>
                                        <li><span>Ensuring platform functionality, security, and compatibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Telemedicine & Lab Integration Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring integration with telemedicine services for collaborative patient care.</span></li>
                                        <li><span>Coordination with telemedicine providers for efficient service delivery.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Payment Integration Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of integration with billing systems for processing claims.</span></li>
                                        <li><span>Support for resolving issues related to billing and payment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support for Labs</h3>
                                    <ul>
                                        <li><span>Resources for lab training on platform usage and protocols.</span></li>
                                        <li><span>Ongoing support and assistance for technical or platform-related issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Quality Control & Assurance Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for ensuring the accuracy and reliability of lab tests.</span></li>
                                        <li><span>Support for maintaining high-quality testing standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Response Coordination Tools</h3>
                                    <ul>
                                        <li><span>Coordination tools for managing emergency situations in labs.</span></li>
                                        <li><span>Support for rapid response and assistance during critical events.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Mobile App</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Labs Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Admin Web Panel</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;