import React from 'react';
import { FaUserDoctor } from "react-icons/fa6";
import { RiMedicineBottleLine, RiHealthBookLine } from "react-icons/ri";
import { BsFileEarmarkMedical, BsShieldLock } from "react-icons/bs";
import { FaFileMedicalAlt, FaNotesMedical, FaHandHoldingMedical, FaBriefcaseMedical } from "react-icons/fa";
import { LuTimerReset, LuMonitorDot } from "react-icons/lu";
import { MdOutlineMedicationLiquid } from "react-icons/md";
import { TbUserStar, TbDeviceWatch } from "react-icons/tb";
import { IoCalendarOutline } from "react-icons/io5";

const FeaturesSection = () => {
    return (
        <>
            <section className="features__area">
                <div className="container-fluid p-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-xl-3">
                            <div className="section__title section__title-3 text-center" style={{ padding: '56px 0px' }}>
                                <h2 className="mb-0">Key Features</h2>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><FaUserDoctor /></i>
                                    </div>
                                    <div>
                                        <h3>Telemedicine Consultations</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><RiMedicineBottleLine /></i>
                                    </div>
                                    <div>
                                        <h3>E-Pharmacy Services</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><BsFileEarmarkMedical /></i>
                                    </div>
                                    <div>
                                        <h3>Digital Prescription Management</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><FaFileMedicalAlt /></i>
                                    </div>
                                    <div>
                                        <h3>Online Diagnostic Booking</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><LuTimerReset /></i>
                                    </div>
                                    <div>
                                        <h3>Real-Time Availability</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><RiHealthBookLine /></i>
                                    </div>
                                    <div>
                                        <h3>Digital Health Records</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><FaNotesMedical /></i>
                                    </div>
                                    <div>
                                        <h3>Patient Access to Records</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><FaHandHoldingMedical /></i>
                                    </div>
                                    <div>
                                        <h3>Interoperability</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><BsShieldLock /></i>
                                    </div>
                                    <div>
                                        <h3>Secure Communication</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><MdOutlineMedicationLiquid /></i>
                                    </div>
                                    <div>
                                        <h3>Medication Information</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><IoCalendarOutline /></i>
                                    </div>
                                    <div>
                                        <h3>Appointment Reminders</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><LuMonitorDot /></i>
                                    </div>
                                    <div>
                                        <h3>User-Friendly Interface</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><FaBriefcaseMedical /></i>
                                    </div>
                                    <div>
                                        <h3>HIPAA Compliance</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item bg">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><TbUserStar /></i>
                                    </div>
                                    <div>
                                        <h3>Feedback & Ratings System</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="features__item">
                                <div className="features__content">
                                    <div className="features__icon">
                                        <i><TbDeviceWatch /></i>
                                    </div>
                                    <div>
                                        <h3>Telehealth Monitoring</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 align-items-center">
                        <div className="col-xl-6">
                            <div className="bgOne"></div>
                        </div>
                        <div className="col-xl-6">
                            <div className="bgTwo"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturesSection;