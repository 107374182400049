import React from 'react';

const ClientSingle = ({ img_1 }) => {
    return (
        <>
            <div className="brand__item-wrapper">
                <div className="brand__item m-img r-img">
                    <img src={`assets/img/clients/${img_1}.jpg`} alt="Sjain" />
                </div>
            </div>
        </>
    );
};

export default ClientSingle;