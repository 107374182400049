import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("store")}>Store Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for E-Pharmacy</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Interface</h3>
                                    <ul>
                                        <li><span>Intuitive and easy-to-navigate app or website design.</span></li>
                                        <li><span>Clear categorization of products for efficient browsing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Account Creation & Profiles</h3>
                                    <ul>
                                        <li><span>Simple account creation for personalized experiences.</span></li>
                                        <li><span>User profiles with order history and preferences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Product Search & Filters</h3>
                                    <ul>
                                        <li><span>Robust search functionality for finding specific medications.</span></li>
                                        <li><span>Filters for refining search results based on brand, price, or health conditions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Detailed Product Information</h3>
                                    <ul>
                                        <li><span>Comprehensive product descriptions and usage instructions.</span></li>
                                        <li><span>Access to information on side effects and precautions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Upload & Verification</h3>
                                    <ul>
                                        <li><span>Secure options for uploading and verifying prescriptions.</span></li>
                                        <li><span>Integration with healthcare providers for prescription validation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medication Reminders</h3>
                                    <ul>
                                        <li><span>Built-in tools for setting medication reminders.</span></li>
                                        <li><span>Notifications for prescription refills and dosage schedules.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Checkout & Payment Options</h3>
                                    <ul>
                                        <li><span>Secure payment gateways for safe transactions.</span></li>
                                        <li><span>Multiple payment options, including credit cards and digital wallets.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Order Tracking & Notifications</h3>
                                    <ul>
                                        <li><span>Real-time tracking of order status and delivery.</span></li>
                                        <li><span>Notifications for order confirmation, dispatch, and delivery.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>In-App Consultations</h3>
                                    <ul>
                                        <li><span>Integration with telemedicine for virtual consultations.</span></li>
                                        <li><span>Access to healthcare professionals for advice on medications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Automatic Refill Options</h3>
                                    <ul>
                                        <li><span>Options for automatic prescription refills.</span></li>
                                        <li><span>Reminders and notifications for upcoming refills.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Discounts & Promotions</h3>
                                    <ul>
                                        <li><span>Special discounts for recurring prescriptions.</span></li>
                                        <li><span>Promotional offers and loyalty programs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Reviews & Ratings</h3>
                                    <ul>
                                        <li><span>User-generated reviews for medications and services.</span></li>
                                        <li><span>Ratings for products based on user experiences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Privacy & Data Security</h3>
                                    <ul>
                                        <li><span>Robust privacy controls for user data.</span></li>
                                        <li><span>Compliance with healthcare data protection regulations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Integration</h3>
                                    <ul>
                                        <li><span>Integration with customer support channels for inquiries.</span></li>
                                        <li><span>Live chat or helpline for immediate assistance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources</h3>
                                    <ul>
                                        <li><span>Access to educational resources on medications and health conditions.</span></li>
                                        <li><span>Informational articles and videos for user awareness.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Subscription Services</h3>
                                    <ul>
                                        <li><span>Subscription models for regular medication deliveries.</span></li>
                                        <li><span>Auto-refill subscriptions for convenience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Locator</h3>
                                    <ul>
                                        <li><span>Integration with maps for locating nearby pharmacies.</span></li>
                                        <li><span>Information on pharmacy hours and services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medication Interaction Checker</h3>
                                    <ul>
                                        <li><span>Feature for checking potential interactions between medications.</span></li>
                                        <li><span>Alerts and recommendations for safer medication use.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Easy Returns & Refunds</h3>
                                    <ul>
                                        <li><span>User-friendly process for returns and refunds.</span></li>
                                        <li><span>Transparent policies for handling product-related issues.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="store">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Store Side Features for E-Pharmacy</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Inventory Management</h3>
                                    <ul>
                                        <li><span>Robust inventory tracking system for medications and healthcare products.</span></li>
                                        <li><span>Real-time updates on stock levels, expirations, and product availability.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Verification System</h3>
                                    <ul>
                                        <li><span>Secure system for verifying and validating prescriptions.</span></li>
                                        <li><span>Integration with healthcare providers for efficient prescription checks.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Order Processing Dashboard</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for managing incoming orders.</span></li>
                                        <li><span>Tools for order processing, fulfillment, and dispatch.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Payment Gateway Integration</h3>
                                    <ul>
                                        <li><span>Integration with secure payment gateways for online transactions.</span></li>
                                        <li><span>Options for processing various payment methods.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Database Management</h3>
                                    <ul>
                                        <li><span>Database for storing customer information and order history.</span></li>
                                        <li><span>Tools for maintaining accurate and secure customer records.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Promotions & Discounts Management</h3>
                                    <ul>
                                        <li><span>Tools for creating and managing promotional campaigns.</span></li>
                                        <li><span>Discount settings and loyalty program management.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Delivery Management System</h3>
                                    <ul>
                                        <li><span>Integration with delivery systems for efficient order fulfillment.</span></li>
                                        <li><span>Real-time tracking of delivery status and updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Communication Tools</h3>
                                    <ul>
                                        <li><span>Communication channels for order confirmations and updates.</span></li>
                                        <li><span>Notification system for prescription verifications and product availability.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Returns & Refunds Processing</h3>
                                    <ul>
                                        <li><span>Efficient system for handling returns and processing refunds.</span></li>
                                        <li><span>Customer support tools for addressing product-related issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medication Interaction Alerts</h3>
                                    <ul>
                                        <li><span>Integration with databases for checking potential medication interactions.</span></li>
                                        <li><span>Alerts and recommendations for safe medication dispensing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Integration</h3>
                                    <ul>
                                        <li><span>Integration with customer support channels for query resolution.</span></li>
                                        <li><span>Tools for responding to customer inquiries and concerns.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics Dashboard</h3>
                                    <ul>
                                        <li><span>Comprehensive analytics dashboard for sales, order trends, and customer behavior.</span></li>
                                        <li><span>Reporting tools for insights into popular products and promotions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Compliance Measures</h3>
                                    <ul>
                                        <li><span>Implementation of security measures to protect customer and transaction data.</span></li>
                                        <li><span>Compliance with healthcare and data protection regulations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Information Display</h3>
                                    <ul>
                                        <li><span>Tools for displaying accurate information about the pharmacy.</span></li>
                                        <li><span>Information on hours of operation, services offered, and contact details.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Healthcare Providers</h3>
                                    <ul>
                                        <li><span>Integration with healthcare providers for collaborative patient care.</span></li>
                                        <li><span>Access to patient records with consent for better prescription management.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources Management</h3>
                                    <ul>
                                        <li><span>Tools for creating and managing educational content.</span></li>
                                        <li><span>Integration with the platform for user awareness campaigns.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Locator Integration</h3>
                                    <ul>
                                        <li><span>Integration with maps for displaying the pharmacy's location.</span></li>
                                        <li><span>Information on nearby pharmacies for customer convenience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Subscription Service Management</h3>
                                    <ul>
                                        <li><span>Tools for managing subscription services and auto-refills.</span></li>
                                        <li><span>Options for configuring subscription plans and discounts.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for E-Pharmacy</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User & Pharmacy Management</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for managing user and pharmacy accounts.</span></li>
                                        <li><span>Tools for user verification, account management, and pharmacy onboarding.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Inventory Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of the pharmacy's inventory and stock levels.</span></li>
                                        <li><span>Alerts for low stock, expired products, and inventory management tools.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Order Processing & Verification</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for monitoring and processing incoming orders.</span></li>
                                        <li><span>Tools for verifying prescriptions and ensuring compliance with regulations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Data Protection</h3>
                                    <ul>
                                        <li><span>Implementation of robust security measures to protect sensitive data.</span></li>
                                        <li><span>Compliance with healthcare data protection regulations and standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span>Comprehensive analytics dashboard for tracking pharmacy performance.</span></li>
                                        <li><span>Reports on sales, order trends, customer demographics, and product popularity.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication & Announcements</h3>
                                    <ul>
                                        <li><span>Communication tools for interacting with pharmacies.</span></li>
                                        <li><span>Announcement features for platform-wide updates, policy changes, and news.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Promotions & Discounts Oversight</h3>
                                    <ul>
                                        <li><span>Tools for managing and overseeing promotional campaigns.</span></li>
                                        <li><span>Discount settings and oversight of loyalty programs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Performance Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for assessing pharmacy performance.</span></li>
                                        <li><span>Metrics related to order fulfillment speed, customer satisfaction, and compliance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Management</h3>
                                    <ul>
                                        <li><span>Integration with customer support channels for pharmacy inquiries.</span></li>
                                        <li><span>Oversight of customer support interactions and query resolution.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Verification & Compliance</h3>
                                    <ul>
                                        <li><span>Verification processes for onboarding new pharmacies.</span></li>
                                        <li><span>Regular checks to ensure pharmacies adhere to platform guidelines.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Verification Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing the prescription verification process.</span></li>
                                        <li><span>Support for resolving issues related to prescription validation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Customization & Settings</h3>
                                    <ul>
                                        <li><span>Controls for customizing platform settings and configurations.</span></li>
                                        <li><span>Adaptability to changing market conditions and regulatory requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Refund & Returns Management</h3>
                                    <ul>
                                        <li><span>Oversight of the refund and returns process.</span></li>
                                        <li><span>Handling customer inquiries and disputes related to refunds.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Environmental Impact Monitoring</h3>
                                    <ul>
                                        <li><span>Tools for tracking and reporting on the environmental impact of packaging and deliveries.</span></li>
                                        <li><span>Integration with sustainability initiatives and reporting.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Healthcare Providers</h3>
                                    <ul>
                                        <li><span>Oversight of integrations with healthcare providers.</span></li>
                                        <li><span>Collaboration tools for better patient care and prescription management.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources Oversight</h3>
                                    <ul>
                                        <li><span>Tools for managing and updating educational content.</span></li>
                                        <li><span>Oversight of user awareness campaigns and educational initiatives.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Updates & Maintenance</h3>
                                    <ul>
                                        <li><span>Tools for managing updates to the platform and applications.</span></li>
                                        <li><span>Ensuring platform functionality, security, and compatibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medication Interaction Monitoring</h3>
                                    <ul>
                                        <li><span>Oversight of the medication interaction checking system.</span></li>
                                        <li><span>Addressing potential issues and ensuring user safety.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pharmacy Ratings & Reviews</h3>
                                    <ul>
                                        <li><span>Oversight of user-generated ratings and reviews for pharmacies.</span></li>
                                        <li><span>Mechanisms for addressing pharmacy-related concerns and feedback.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Subscription Service Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring and overseeing pharmacy subscription services.</span></li>
                                        <li><span>Ensuring the smooth operation of auto-refill subscriptions and plans.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Mobile App</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Store Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Admin Web Panel</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;