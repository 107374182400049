import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="robots" content="index, follow" />
                <title>{pageTitle} - eHealth</title>
                <meta name="description" content="Explore how eHealth solutions are transforming healthcare through cutting-edge technologies, enhancing accessibility, and improving patient outcomes. Discover the future of digital health." />
            </Helmet>
        </>
    );
};

export default PageHelmet;