import React from 'react';

const SolutionSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative grey-bg-12 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/home-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover', left: '0' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 offset-lg-7">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-40">
                                    <h2>E-Health Solution for:</h2>
                                </div>
                                <div className="capabilities__list">
                                    <ol>
                                        <li>Online E-Pharmacy</li>
                                        <li>Telemedicine</li>
                                        <li>Online Labs & Diagnostic Booking</li>
                                        <li>Digital Health Records</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SolutionSection;