import React from 'react';

const FaqSection = () => {
    return (
        <>
            <section className="faq__area pt-100 pb-100 grey-bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="accordionExample">
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    What is an E-Health platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>An E-Health platform is a digital ecosystem that integrates various healthcare services. It typically includes features such as online E-Pharmacy, telemedicine, online labs and diagnostic booking, and digital health records.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    How does the Online E-Pharmacy work on the platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Online E-Pharmacy on the platform allows users to purchase medications and healthcare products online, providing a convenient and accessible way to fulfill prescriptions and order over-the-counter medicines.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    What is Telemedicine, and how does it function on the E-Health platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Telemedicine refers to the remote provision of healthcare services through electronic communication. On the E-Health platform, telemedicine allows users to consult with healthcare professionals virtually, enabling medical advice, diagnosis, and even treatment from the comfort of their homes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    Can I book labs and diagnostic tests online through the platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the platform facilitates online booking for labs and diagnostic tests, providing users with the convenience of scheduling and managing their diagnostic appointments from anywhere.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4 mb-lg-0">
                                        <div className="card-header" id="acc_5">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="true" aria-controls="collapse_5">
                                                    How are Digital Health Records managed on the E-Health platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The E-Health platform securely stores and manages Digital Health Records, allowing users to access and share their medical history and information with healthcare providers as needed, ensuring comprehensive and coordinated care.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header" id="acc_6">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="true" aria-controls="collapse_6">
                                                    Is my information secure when using the E-Health platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_6" className="collapse" aria-labelledby="acc_6" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the platform prioritizes the security and confidentiality of user data, employing robust encryption and privacy measures to safeguard personal health information.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_7">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_7" aria-expanded="true" aria-controls="collapse_7">
                                                    Can I get my prescription medications delivered through the Online E-Pharmacy?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_7" className="collapse" aria-labelledby="acc_7" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Absolutely, the Online E-Pharmacy on the platform offers the convenience of doorstep delivery for prescription medications, ensuring timely access to essential healthcare products.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_8">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_8" aria-expanded="true" aria-controls="collapse_8">
                                                    How can I schedule a telemedicine appointment with a healthcare professional?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_8" className="collapse" aria-labelledby="acc_8" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Users can easily schedule telemedicine appointments through the platform's user-friendly interface, choosing a suitable time slot and connecting with healthcare providers virtually.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_9">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_9" aria-expanded="true" aria-controls="collapse_9">
                                                    Are there any features to track and manage my health metrics on the E-Health platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_9" className="collapse" aria-labelledby="acc_9" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the platform often includes features for users to track and managE-Health metrics, allowing them to monitor vital signs, medications, and other health-related data for a holistic approach to healthcare.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_10">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_10" aria-expanded="true" aria-controls="collapse_10">
                                                    Can I access my Digital Health Records from multiple devices?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_10" className="collapse" aria-labelledby="acc_10" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the E-Health platform is designed to provide seamless access to Digital Health Records from various devices, ensuring users can manage their health information conveniently and efficiently.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqSection;