import React from 'react';

const PatientSection = () => {
    return (
        <>
            <section className="price__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="price__item fix flex-lg-row flex-column">
                                <div className="price__inner">
                                    <h3>Attract More Patients</h3>
                                    <p>Attract a higher volume of patients by utilizing our extensive All-In-One digital health platform. Covering SEO-optimized websites, reputation management, patient mobile apps, and focused campaigns, we provide all the essential tools for success in the current digital environment.</p>
                                    <ul>
                                        <li><span>Increase patient acquisition through an SEO-optimized, website.</span></li>
                                        <li><span>Elevate your online reputation to attract new patients.</span></li>
                                        <li><span>Establish your brand presence with personalized patient mobile apps.</span></li>
                                        <li><span>Expand your patient base through precisely targeted digital campaigns.</span></li>
                                    </ul>
                                </div>
                                <div className="m-img">
                                    <img src="assets/img/home-img1.jpg" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="price__item flex-lg-row flex-column-reverse">
                                <div className="m-img">
                                    <img src="assets/img/home-img2.jpg" alt="Sjain" />
                                </div>
                                <div className="price__inner-2">
                                    <h3>Give Patients What They Want</h3>
                                    <p>Improve the overall patient experience and provide satisfaction by incorporating convenient features that meet their expectations. This includes options such as online appointment scheduling, automated reminders, online payment capabilities, and secure access to a patient portal.</p>
                                    <ul>
                                        <li><span>Enable online scheduling of appointments.</span></li>
                                        <li><span>Reduce no-show occurrences through automated reminders and notifications.</span></li>
                                        <li><span>Facilitate streamlined payments with online payment alternatives.</span></li>
                                        <li><span>Ensure secure access to patient information via a dedicated patient portal.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="price__item flex-lg-row flex-column">
                                <div className="price__inner">
                                    <h3>Improve Patient Care</h3>
                                    <p>Improve patient care by offering diverse care delivery alternatives, such as telehealth video consultations, subscription care plans, and asynchronous text consultations. Enhance care delivery through enhanced communication and patient education.</p>
                                    <ul>
                                        <li><span>Provide telehealth video appointments.</span></li>
                                        <li><span>Extend continuous care through subscription care plans.</span></li>
                                        <li><span>Offer asynchronous text consultations.</span></li>
                                        <li><span>Educate patients through informative content and personalized messages.</span></li>
                                    </ul>
                                </div>
                                <div className="m-img">
                                    <img src="assets/img/home-img3.jpg" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="price__item last flex-lg-row flex-column-reverse">
                                <div className="m-img">
                                    <img src="assets/img/home-img4.jpg" alt="Sjain" />
                                </div>
                                <div className="price__inner-2">
                                    <h3>Practice Management Simplified</h3>
                                    <p>Effectively oversee your patients, team, billing, and overall operations through a comprehensive digital health platform.</p>
                                    <ul>
                                        <li><span>Utilize user-friendly patient notes.</span></li>
                                        <li><span>Effectively handle billing through creditsand comprehensive reports.</span></li>
                                        <li><span>Establish professional profile pages for healthcare providers.</span></li>
                                        <li><span>Specify services and team schedules.</span></li>
                                        <li><span>Assist patients in fulfilling their prescriptions and undergoing lab tests.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PatientSection;