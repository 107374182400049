import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("doctor")}>Doctor Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for Telemedicine</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Interface</h3>
                                    <ul>
                                        <li><span>Intuitive and easy-to-use platform for seamless navigation.</span></li>
                                        <li><span>Clear categorization of features for user-friendly access.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Account Creation & Profile Management</h3>
                                    <ul>
                                        <li><span>Simple and secure account creation for personalized experiences.</span></li>
                                        <li><span>User profiles with medical history, preferences, and appointment records.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling</h3>
                                    <ul>
                                        <li><span>Online calendar for scheduling telemedicine appointments.</span></li>
                                        <li><span>Notifications and reminders for upcoming consultations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Video Consultations</h3>
                                    <ul>
                                        <li><span>High-quality and secure video conferencing for virtual consultations.</span></li>
                                        <li><span>Encrypted communication channels to ensure patient privacy.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Text-based Communication</h3>
                                    <ul>
                                        <li><span>In-app or platform messaging for non-urgent queries.</span></li>
                                        <li><span>Secure text-based communication for follow-up questions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medical History Access</h3>
                                    <ul>
                                        <li><span>Access to personal medical history and records.</span></li>
                                        <li><span>Integration with electronic health records for a comprehensive overview.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Management</h3>
                                    <ul>
                                        <li><span>Electronic prescription issuance and management.</span></li>
                                        <li><span>Secure transmission of prescriptions to pharmacies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Monitoring Integration</h3>
                                    <ul>
                                        <li><span>Integration with health monitoring devices for real-time data.</span></li>
                                        <li><span>Monitoring of vital signs and chronic conditions from home.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Patient Education Resources</h3>
                                    <ul>
                                        <li><span>Access to educational materials on health conditions and treatments.</span></li>
                                        <li><span>Informational articles and videos for user awareness.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Multilingual Support</h3>
                                    <ul>
                                        <li><span>Support for multiple languages to cater to diverse user bases.</span></li>
                                        <li><span>Interpretation services for non-native speakers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span>User-friendly mobile applications for on-the-go consultations.</span></li>
                                        <li><span>Compatibility with various devices for convenience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Payment & Billing Integration</h3>
                                    <ul>
                                        <li><span>Seamless integration with payment gateways for consultation fees.</span></li>
                                        <li><span>Electronic billing and invoicing for transparent transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment History & Records</h3>
                                    <ul>
                                        <li><span>Overview of past telemedicine appointments and consultations.</span></li>
                                        <li><span>Detailed records of diagnoses, treatments, and recommendations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Medication Reminders</h3>
                                    <ul>
                                        <li><span>Automated reminders for medication schedules.</span></li>
                                        <li><span>Integration with prescription information for accuracy.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Insurance Verification</h3>
                                    <ul>
                                        <li><span>Tools for verifying insurance coverage and processing claims.</span></li>
                                        <li><span>Integration with insurance systems for streamlined processes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Family Account Management</h3>
                                    <ul>
                                        <li><span>Features for managing health profiles for family members.</span></li>
                                        <li><span>Coordinated scheduling for family consultations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Second Opinion Consultations</h3>
                                    <ul>
                                        <li><span>Options for seeking second opinions from different healthcare professionals.</span></li>
                                        <li><span>Tools for sharing medical records securely for consultations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Remote Monitoring & Follow-Up</h3>
                                    <ul>
                                        <li><span>Remote monitoring tools for ongoing health conditions.</span></li>
                                        <li><span>Follow-up consultations and check-ins for continuity of care.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Rating System</h3>
                                    <ul>
                                        <li><span>User feedback system for rating telemedicine experiences.</span></li>
                                        <li><span>Mechanisms for continuous improvement based on user suggestions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Services Integration</h3>
                                    <ul>
                                        <li><span>Integration with emergency services for urgent situations.</span></li>
                                        <li><span>Coordination with local emergency response teams when needed.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="doctor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Doctor Side Features for Telemedicine</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Login & Authentication</h3>
                                    <ul>
                                        <li><span>Secure login with multi-factor authentication for doctor accounts.</span></li>
                                        <li><span>Verification processes to ensure the authenticity of healthcare professionals.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Schedule & Management</h3>
                                    <ul>
                                        <li><span>Centralized calendar for managing telemedicine appointments.</span></li>
                                        <li><span>Tools for setting availability, blocking time slots, and handling scheduling preferences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Patient Records & History Access</h3>
                                    <ul>
                                        <li><span>Access to patients' medical records and history.</span></li>
                                        <li><span>Integration with electronic health records for a comprehensive overview.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Video Consultation Platform</h3>
                                    <ul>
                                        <li><span>High-quality, encrypted video conferencing for virtual consultations.</span></li>
                                        <li><span>HIPAA-compliant communication channels to ensure patient confidentiality.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Text-Based Communication</h3>
                                    <ul>
                                        <li><span>In-app or platform messaging for secure and non-urgent communication.</span></li>
                                        <li><span>Options for handling follow-up queries and providing information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Management</h3>
                                    <ul>
                                        <li><span>Electronic prescription issuance and management.</span></li>
                                        <li><span>Integration with pharmacy systems for seamless prescription transmission.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Clinical Decision Support Tools</h3>
                                    <ul>
                                        <li><span>Access to clinical decision support tools for real-time assistance.</span></li>
                                        <li><span>Integration with databases and resources for evidence-based recommendations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Monitoring Integration</h3>
                                    <ul>
                                        <li><span>Integration with patient health monitoring devices for real-time data.</span></li>
                                        <li><span>Monitoring of vital signs and chronic conditions remotely.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Telemedicine Notes & Documentation</h3>
                                    <ul>
                                        <li><span>Tools for taking detailed consultation notes and documenting findings.</span></li>
                                        <li><span>Digital documentation features for accuracy and completeness.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Multidisciplinary Collaboration</h3>
                                    <ul>
                                        <li><span>Collaboration tools for multidisciplinary care coordination.</span></li>
                                        <li><span>Secure sharing of patient information with specialists when needed.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure File Sharing</h3>
                                    <ul>
                                        <li><span>Secure file-sharing capabilities for sharing documents and reports.</span></li>
                                        <li><span>HIPAA-compliant file transfer to ensure data security.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Digital Signature for Documents</h3>
                                    <ul>
                                        <li><span>Tools for digitally signing prescriptions and medical documents.</span></li>
                                        <li><span>Secure and legally compliant digital signatures.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Consultation Features</h3>
                                    <ul>
                                        <li><span>Integration with emergency services for urgent consultations.</span></li>
                                        <li><span>Coordination with emergency response teams in critical situations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Insurance Verification & Billing</h3>
                                    <ul>
                                        <li><span>Verification tools for patient insurance coverage.</span></li>
                                        <li><span>Integration with billing systems for processing claims.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Continuing Medical Education (CME) Tracking</h3>
                                    <ul>
                                        <li><span>Tracking tools for continuing medical education credits.</span></li>
                                        <li><span>Integration with CME platforms for seamless recordkeeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Telemedicine Training & Support</h3>
                                    <ul>
                                        <li><span>Training resources for doctors new to telemedicine practices.</span></li>
                                        <li><span>Ongoing support and assistance for technical or platform-related issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Interactive Patient Education</h3>
                                    <ul>
                                        <li><span>Tools for interactive patient education during virtual consultations.</span></li>
                                        <li><span>Integration with educational resources for real-time information sharing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Rating System</h3>
                                    <ul>
                                        <li><span>System for receiving feedback and ratings from patients.</span></li>
                                        <li><span>Mechanisms for continuous improvement based on patient experiences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Remote Monitoring Alerts</h3>
                                    <ul>
                                        <li><span>Alerts for critical changes in patient health data.</span></li>
                                        <li><span>Notification system for timely intervention in case of emergencies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Remote Second Opinion Services</h3>
                                    <ul>
                                        <li><span>Tools for providing remote second opinions to patients.</span></li>
                                        <li><span>Secure methods for accessing and reviewing medical records for consultations.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Telemedicine</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User & Doctor Management</h3>
                                    <ul>
                                        <li><span>Centralized dashboard for managing user and doctor accounts.</span></li>
                                        <li><span>Tools for doctor verification, account management, and onboarding.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for overseeing telemedicine appointment schedules.</span></li>
                                        <li><span>Support for managing scheduling conflicts and ensuring efficiency.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Compliance Oversight</h3>
                                    <ul>
                                        <li><span>Implementation of robust security measures to protect patient data.</span></li>
                                        <li><span>Compliance monitoring with healthcare data protection regulations and standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Customization & Settings</h3>
                                    <ul>
                                        <li><span>Controls for customizing platform settings and configurations.</span></li>
                                        <li><span>Adaptability to changing market conditions and regulatory requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics Dashboard</h3>
                                    <ul>
                                        <li><span>Comprehensive analytics dashboard for tracking platform performance.</span></li>
                                        <li><span>Reports on appointment trends, user demographics, and user satisfaction.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication & Announcement Tools</h3>
                                    <ul>
                                        <li><span>Tools for communicating with users and doctors.</span></li>
                                        <li><span>Announcement features for platform-wide updates, policy changes, and news.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Doctor Verification & Compliance</h3>
                                    <ul>
                                        <li><span>Verification processes for onboarding new doctors.</span></li>
                                        <li><span>Regular checks to ensure doctors adhere to platform guidelines.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Telemedicine Notes & Documentation Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing and managing telemedicine notes and documentation.</span></li>
                                        <li><span>Compliance checks to ensure accurate and secure recordkeeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Prescription Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for overseeing the prescription issuance process.</span></li>
                                        <li><span>Support for resolving issues related to prescription validation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Insurance Verification Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing insurance verification processes.</span></li>
                                        <li><span>Assistance in addressing issues related to insurance claims and coverage.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Services Integration Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of integration with emergency services for urgent consultations.</span></li>
                                        <li><span>Coordination with emergency response teams in critical situations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Patient & Doctor Feedback System</h3>
                                    <ul>
                                        <li><span>System for collecting feedback and ratings from patients and doctors.</span></li>
                                        <li><span>Analysis of feedback for continuous platform improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Continuing Medical Education (CME) Tracking Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of tools for tracking doctors' continuing medical education credits.</span></li>
                                        <li><span>Integration with CME platforms for seamless recordkeeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Updates & Maintenance</h3>
                                    <ul>
                                        <li><span>Tools for managing updates to the telemedicine platform and applications.</span></li>
                                        <li><span>Ensuring platform functionality, security, and compatibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Remote Monitoring & Alerts Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of remote monitoring alerts for critical changes in patient health.</span></li>
                                        <li><span>Notification system for timely intervention in case of emergencies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Patient Education Resources Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing and updating patient education resources.</span></li>
                                        <li><span>Coordination with healthcare organizations for resource accuracy.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Second Opinion Consultations Oversight</h3>
                                    <ul>
                                        <li><span>Monitoring tools for providing oversight in second opinion consultations.</span></li>
                                        <li><span>Ensuring secure access and review of medical records for consultations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Payment Integration Oversight</h3>
                                    <ul>
                                        <li><span>Oversight of integration with billing systems for processing claims.</span></li>
                                        <li><span>Support for resolving issues related to billing and payment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Environmental Impact Monitoring</h3>
                                    <ul>
                                        <li><span>Tools for tracking and reporting on the environmental impact of virtual consultations.</span></li>
                                        <li><span>Integration with sustainability initiatives and reporting.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support for Users & Doctors</h3>
                                    <ul>
                                        <li><span>Resources for user and doctor training on telemedicine platform usage.</span></li>
                                        <li><span>Ongoing support and assistance for technical or platform-related issues.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customer Mobile App</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Doctor Web Panel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Admin Web Panel</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;