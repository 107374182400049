import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import SolutionSection from './SolutionSection';
import PatientSection from './PatientSection';
import ClientSection from './ClientSection';
import PartnerSection from './PartnerSection';
import FaqSection from './FaqSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="E-Health Solutions Revolutionizing Healthcare with Innovative Technologies" />
            <Header />
            <HeroSection />
            <FeaturesSection />
            <SolutionSection />
            <PatientSection />
            <ClientSection />
            <PartnerSection />
            <FaqSection />
            <Footer />
        </>
    );
};

export default Home;