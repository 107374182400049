import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/Helper/ScrollTop";
import BackToTop from "./components/Helper/BackToTop";
import Home from './components/Home/index';
import About from './components/About/index';
import Blog from './components/Blog/index';
import Contact from './components/Contact/index';
import Epharmacy from './components/Epharmacy/index';
import Telemedicine from './components/Telemedicine/index';
import Labs from './components/Labs/index';


function App() {
    return (
        <>
            {/* <Router basename="/name"> */}
            <Router>
                <ScrollTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/features/e-pharmacy" element={<Epharmacy />} />
                    <Route path="/features/telemedicine" element={<Telemedicine />} />
                    <Route path="/features/labs" element={<Labs />} />
                </Routes>
                <BackToTop />
            </Router>
        </>
    );
}

export default App;
