import React from 'react';

const HeadSection = () => {
    return (
        <>
            <section className="price__area gradient-bg pt-80 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center">
                                <h2 className="white-color mb-0">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeadSection;