import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative grey-bg-12 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/about-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-25">
                                    <h2>We Offers!</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p>In the ever-evolving landscape of healthcare, Sjain Ventures has emerged as a trailblazer through its state-of-the-art E-Health Management System. This comprehensive ecosystem underscores the organization's commitment to harnessing technology for the advancement of healthcare services. At the core of this system lie crucial elements such as E-Pharmacy, Telemedicine, Health Records, Labs, and Diagnostics, seamlessly fused to offer an all-encompassing and patient-focused approach to healthcare administration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <p>Sjain Ventures' <span>E-Pharmacy</span> platform introduces a groundbreaking solution that transforms how individuals obtain medications. Through an intuitive online interface, patients can conveniently explore, order, and receive prescribed medicines and healthcare products at their doorstep. This not only ensures accessibility but also encourages adherence to treatment plans, fostering an efficient and user-friendly pharmaceutical experience.</p>
                                    <p>Telemedicine, an integral facet of Sjain Ventures' <span>E-Health Management System</span>, bridges the gap between healthcare professionals and patients, irrespective of geographical constraints. This digital platform facilitates remote consultations, enabling individuals to seek medical advice, receive diagnoses, and access personalized treatment plans from the comfort of their homes. By removing geographical barriers, Sjain Ventures empowers patients to prioritize their health with enhanced convenience.</p>
                                    <p>The digital transformation extends to Health Records, where Sjain Ventures provides a secure and centralized repository for individuals to manage their medical histories. This feature not only improves communication between healthcare providers but also enables patients to actively participate in their healthcare decisions by easily accessing their health records.</p>
                                    <p>Sjain Ventures reinforces its commitment to comprehensive healthcare solutions by seamlessly integrating Labs and Diagnostics into its <span>E-Health Management System</span>. Users can effortlessly schedule diagnostic tests, receive results electronically, and even consult with healthcare professionals, creating a streamlined and efficient diagnostic process.</p>
                                    <p className="mb-0">In summary, Sjain Ventures' <span>E-Health Management System</span> serves as a testament to the organization's dedication to revolutionizing healthcare delivery. Through the seamless integration of <span>E-Pharmacy</span>, Telemedicine, Health Records, Labs, and Diagnostics, Sjain Ventures is shaping the future of healthcare management, where technology acts as a catalyst for accessibility, efficiency, and patient-centric care.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ContentSection;